import * as Alerts from '@/shared/Alerts.mjs'
import { APIResponse, APIResponseAction, APIResult } from '@/classes/APIResponse.ts'

export async function getAccessToken() {
  const response = await fetch('/account/accesstoken')
    .then(status)
    .then(json)
    .then((data) => {
      if (data.access_token) {
        return Promise.resolve(data.access_token)
      } else {
        return Promise.reject(data.error)
      }
    })
    .catch((error) => {
      console.log(`Access Token error:${error}`)
      return Promise.reject(error)
    })
    return await response
}

export async function getRemoteAccessToken() {
  const response = await fetch('/account/RemoteAccessToken')
    .then(status)
    .then(json)
    .then((data) => {
      if (data.access_token) {
        return Promise.resolve(data.access_token)
      } else {
        return Promise.reject(data.error)
      }
    })
    .catch((error) => {
      console.log(`Access Token error:${error}`)
      return Promise.reject(error)
    })
    return await response
}

/**
 * Returns a promise with the response if a valid 200 response is found
 *
 * @param {respones} x response from fetch
 * @return {Promise} Promise containing the response if OK, otherwise Promise with error
 */
export function status(response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response)
  } else if (response.status == 401) {
    return Promise.reject(new Error("401 Unauthorized"))
  }
  return Promise.reject(new Error(response.statusText))
}

export function json(response) {
  return response.json()
}

/**
 * Handles APIResponses by checking for flash messages or redirects
 *
 * @param {response} x function to be run on onSuccess
 * @param {flastTItle} * [String] TItle to be shown in flash dialog
 * @return {Promise} Promise containing the response if OK, otherwise Promise with error
 */
export function flash(response, flashTitle = 'Notice') {
  const responseObj = new APIResponse(response)
  if (responseObj.getAction() === APIResponseAction.FlashMessage) {
    Alerts.setToast(responseObj.actionDetail, 'notice', flashTitle)
  } else if (responseObj.getResult() === APIResult.Success) {
    return response
  }
  return Promise.reject(new Error(response.error))
}

export function handleAPIResponse(response){
  const responseObj = new APIResponse(response)
  if (responseObj === null) {
    return Promise.resolve(response)
  }
  if (responseObj.error) {
    return Promise.reject(responseObj.error)
  }
  if (responseObj.getAction() === APIResponseAction.None) {
    return Promise.resolve(responseObj)
  }
  if (responseObj.getAction() === APIResponseAction.FlashMessage) {
    Alerts.setToast(responseObj.actionDetail, 'notice', 'API Response')
    return Promise.resolve(response)
  }
  if (responseObj.getAction() === APIResponseAction.Redirect) {
    window.location.href = responseObj.actionDetails
  } else if (responseObj.getAction() === APIResponseAction.Reload) {
    window.location.reload()
  }
  return Promise.resolve(response)
}

export function isRedirected(response) {
  if (response.redirected) {
    window.location.href = response.url
  }
  return response.json()
}
