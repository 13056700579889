/* eslint-disable no-param-reassign */
/**
 * Hides the modal as defined by the given element. Takes an optional callback function to process subsequent rest actions, such as clearing text boxes.
 *
 * @param {object} elem element to search
 * @param {function} resetCallback callback function to run after hiding the modal
 * @param {Boolean} cleanupModal Remove modal from the DOM. Default alse
 */
 export function hideModal(selector, resetCallback = () => {}, cleanupModal = false) {
  const elem = document.querySelector(selector)
  elem.classList.remove('show-modal')
  elem.setAttribute('aria-hidden', true)
  elem.removeAttribute('aria-modal')
  elem.removeAttribute('role')

  document.removeEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      hideModal(selector)
    }
  })

  const fieldValidation = elem.querySelector('.field-validation-error')
  if (fieldValidation) {
    fieldValidation.textContent = ''
  }

  const submitButton = elem.querySelector('.js-submit')
  if (submitButton) {
    submitButton.textContent = elem.dataset.submitLabel
    submitButton.disabled = false
    submitButton.classList.remove('button-disabled')
  }
  if (resetCallback !== 'undefined' && resetCallback !== null) {
    resetCallback()
  }

  if (cleanupModal) {
    elem.parentNode.removeChild(elem)
  }
}

/**
 * Displays a given modal element, and adds an escape key listener to close, and a click listener to the close button
 *
 * @param {String} selector selector of the element to show
 * @param {function} func reset function to run when modal is hidden
 * @param {boolean} registerClose register hideMOdal action
 * @param {boolean} cleanup delete modal div once dismisssed
 */
export function showModal(selector, hideResetCallback = () => {}, registerClose = true, cleanup = false) {
  const elem = document.querySelector(selector)
  elem.classList.add('show-modal')
  elem.setAttribute('aria-hidden', false)
  elem.setAttribute('aria-modal', true)
  elem.setAttribute('role', 'dialog')

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      hideModal(selector, hideResetCallback, cleanup)
    }
  })

  if (registerClose) {
    elem.querySelector('.close-button')?.addEventListener('click', () => {
      hideModal(selector, hideResetCallback, cleanup)
    })
  }
}

/**
 * Create a simple modal dialog and display it
 *
 * @param {string} id unique id of the dialog
 * @param {string} title title to be shown
 * @param {string} type dialogstyle notice, warning, danger
 * @param {string} content html content to be inserted in the modal
 * @param {boolean} hideModal include the hideModal event listener. False if you need to handle this yourself
 */
export function createModal(id, title, type, content, registerHideModal = true, hideCallback = () => {}, cleanupModal = false) {
  const modal = document.createElement('div')
  modal.setAttribute('role', 'dialog')
  modal.setAttribute('aria-labelledby', id)
  modal.setAttribute('aria-describedby', 'modalDescription')
  modal.id = id
  modal.classList.add('modal')

  const modalWrapper = document.createElement('div')
  modalWrapper.classList.add('modal-wrapper')

  const modalTitle = document.createElement('div')
  modalTitle.classList.add('modal-title')
  modalTitle.textContent = title
  modalTitle.classList.add(`modal-title-${type}`)

  const closeButton = document.createElement('span')
  closeButton.classList.add('close-button')
  closeButton.setAttribute('aria-label', 'Close modal')
  modalTitle.appendChild(closeButton)
  modalWrapper.appendChild(modalTitle)

  const modalContent = document.createElement('div')
  modalContent.insertAdjacentHTML('afterbegin', content)
  modalContent.classList.add('modal-content')
  const fieldValidation = document.createElement('p')
  fieldValidation.classList.add('field-validation-error')
  modalContent.appendChild(fieldValidation)

  modalWrapper.appendChild(modalContent)
  modal.appendChild(modalWrapper)

  // const doc = new DOMParser().parseFromString(modal, 'text/html')
  document.getElementById('wrapper').after(modal)
  showModal(`#${id}`)
  if (registerHideModal) {
    document.querySelector(`#${id}.modal .close-button`).addEventListener('click', () => {
      hideModal(`#${id}`, hideCallback, cleanupModal)
    })
  }
}
window.createModal = createModal
