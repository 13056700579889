import { v4 as uuidv4 } from 'uuid'
import sanitizeHtml from 'sanitize-html';
import { showModal, hideModal } from './Modals'
// Flash Alert javascripts
const FlashMessageType = {
  Notice: 0,
  Warning: 1,
  Danger: 2
}

Object.freeze(FlashMessageType)

function dismissFlash(element) {
  element.classList.add('dismiss-flash')
  setTimeout(() => {
    element.parentNode.removeChild(element)
  }, 1000)
}
function removeToast(element) {
  document.getElementById('flash-container')?.removeChild(element.target)
}

/**
 * Sends a on screen toast notification
 *
 * @param {string} message the message you want to send to the UI
 * @param {string} type The type of message alert: Primary, Success, Notice, Warning or Danger. Determines toast appearance
 * @param {string} title Dialog title to be shown
 * @param {bool} dismiss Automatically dismiss after a short time. Set to false to remain in screen
 */
/*
<div id="flash-container">
  <div class="alert alert-notice" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="title-block">
    <div class="title">Title</div><a class="close" data-dismiss="alert" aria-label="close">×</a></div>
    <div id="flash_notice"><p>Deleted!</p></div>
  </div>
</div>
*/
export function setToast(message, type, title = '', dismiss = true) {
  create({
    message: message,
    type: type, // notice, success, warning, error
    title: title,
    timeout: dismiss ? 5000 : null
  })
  return

  const flashAlert = document.createElement('div')
  flashAlert.id = 'toast'
  const flashId = new Date().getTime()
  flashAlert.dataset.id = flashId
  flashAlert.classList.add('alert')
  flashAlert.classList.add(`alert-${type}`)
  flashAlert.setAttribute('role', 'alert')
  flashAlert.setAttribute('aria-live', 'assertive')
  flashAlert.setAttribute('aria-atomic', 'true')

  const titleblock = document.createElement('div')
  titleblock.classList.add('title-block')
  const titleEl = document.createElement('div')
  titleEl.classList.add('title')
  if (title !== '') {
    titleEl.textContent = title
  }

  const closeButton = document.createElement('a')
  closeButton.classList.add('close')
  closeButton.setAttribute('data-dismiss', 'alert')
  closeButton.setAttribute('aria-label', 'close')
  closeButton.innerHTML = '&times;'

  const flashMessage = document.createElement('div')
  flashMessage.innerHTML = `<p>${message}</p>`
  flashMessage.id = 'flash_notice'

  titleblock.appendChild(titleEl)
  titleblock.appendChild(closeButton)
  flashAlert.appendChild(titleblock)
  flashAlert.appendChild(flashMessage)
  const container = document.getElementById('flash-container')
  container.insertBefore(flashAlert, container.firstChild)
  document.querySelector(`[data-id="${flashId}"] .close`).addEventListener(
    'click',
    (event) => {
      dismissFlash(event.target.parentNode.parentNode)
    },
    false
  )

  if (dismiss) {
    setTimeout(() => {
      dismissFlash(document.querySelector(`[data-id="${flashId}"] .close`).parentNode.parentNode)
    }, 8000)
  }
  return flashId
}

/**
 * Sends a on screen toast notification
 *
 * @param {string} message the message you want to send to the UI
 * @param {object?} type The type of message alert: Primary, Success, Notice, Warning or Danger. Determines toast appearance
 * @param {object?} title Dialog title to be shown
 * @param {object?} dissmissLabel Label for dismiss. Defaull "Ok"
 * @param {function} onDismiss Automatically dismiss after a short time. Set to false to remain in screen
 */
export function infoAlert(message, params) {
  const optionsObj = {
    title: '',
    type: 'notice',
    dismissLabel: 'OK',
    onDismiss: null,
    ...params
  }
  const modalId = uuidv4()
  const modal = document.createElement('div')
  modal.dataset.id = modalId
  modal.setAttribute('role', 'dialog')
  modal.setAttribute('aria-describedby', 'modalDescription')
  modal.classList.add('modal')

  const modalWrapper = document.createElement('div')
  modalWrapper.classList.add('modal-wrapper')

  const modalTitle = document.createElement('div')
  modalTitle.classList.add('modal-title')
  modalTitle.textContent = optionsObj.title
  modalTitle.classList.add(`modal-title-${optionsObj.type}`)

  const closeButton = document.createElement('span')
  closeButton.classList.add('close-button')
  closeButton.setAttribute('aria-label', 'Close modal')
  modalTitle.appendChild(closeButton)
  modalWrapper.appendChild(modalTitle)

  const flashMessage = document.createElement('div')
  flashMessage.innerHTML = sanitizeHtml(message)

  const modalContent = document.createElement('div')
  modalContent.appendChild(flashMessage)
  modalContent.classList.add('modal-content')

  const modalButtons = document.createElement('div')
  modalButtons.classList.add('action-holder')
  const modalButton = document.createElement('button')
  modalButton.textContent = optionsObj.dismissLabel
  modalButton.classList.add('button')
  modalButton.classList.add('button-dismiss')
  modalButtons.appendChild(modalButton)
  modalWrapper.appendChild(modalContent)
  modalWrapper.appendChild(modalButtons)

  modal.appendChild(modalWrapper)

  // const doc = new DOMParser().parseFromString(modal, 'text/html')
  document.getElementById('wrapper').after(modal)
  document
    .querySelector(`[data-id="${modalId}"].modal .close-button`)
    .addEventListener('click', () => {
      hideModal(`[data-id="${modalId}"].modal`, null, true)
      if (optionsObj.onDismiss) {
        optionsObj.onDismiss()
      }
    })
  document
    .querySelector(`[data-id="${modalId}"].modal .button-dismiss`)
    .addEventListener('click', () => {
      hideModal(`[data-id="${modalId}"].modal`, null, true)
      if (optionsObj.onDismiss) {
        optionsObj.onDismiss()
      }
    })
  showModal(`[data-id="${modalId}"].modal`)
}

export function create(options) {
  let defaultOptions = {
    timeout: 5000
  }
  const toastAlert = document.createElement('div')
  // toastAlert.id = 'toast'
  const flashId = new Date().getTime()
  toastAlert.dataset.id = flashId
  toastAlert.classList.add('toast')
  toastAlert.classList.add(`toast-${options.type}`)
  toastAlert.classList.add(`toast-${flashId}`)
  toastAlert.setAttribute('role', 'alert')
  toastAlert.setAttribute('aria-live', 'assertive')
  toastAlert.setAttribute('aria-atomic', 'true')

  if (options.title) {
    const h4 = document.createElement('h4')
    h4.classList.add('toast-title')
    h4.innerHTML = sanitizeHtml(options.title)
    toastAlert.appendChild(h4)
  }

  // text
  if (options.message) {
    const p = document.createElement('p')
    p.className = 'toast-message'
    p.innerHTML = sanitizeHtml(options.message)
    toastAlert.appendChild(p)
  }

  // icon
  if (options.icon) {
    const img = document.createElement('img')
    img.src = options.icon
    img.className = 'toast-icon'
    toastAlert.appendChild(img)
  }

  const container = document.getElementById('toast-container')
  container.insertBefore(toastAlert, container.firstChild)

  if (typeof options.callback === 'function') {
    if (options.timeout) {
      setTimeout(options.callback, options.timeout)
    } else {
      toastAlert.addEventListener('click', options.callback)
    }
  }

  toastAlert.hide = () => {
    toastAlert.addEventListener('animationend', removeToast, false)
    toastAlert.classList.add('dismiss-toast')
  }
  // autohide
  if (options.timeout) {
    setTimeout(toastAlert.hide, options.timeout)
  }

  if (options.type) {
    toastAlert.classList.add(`toast-${options.type}`)
  }
  toastAlert.addEventListener('click', toastAlert.hide)
  return toastAlert
}
