export const APIResult = Object.freeze({
  Success: 0,
  Failure: 1,
  NotFound: 2,
  Unknown: 3
})

export enum APIResponseAction {
  None = 0,
  Reload =1,
  Redirect =2,
  FlashMessage =3,
}

export class APIResponse {
  result: number;
  action: number;
  actionDetails: any;
  data: any;
  error: any;
  errors: any[];
  fullException: string;
  timestamp: Date;

  constructor(response: any) {
    this.result = response.result;
    this.action = response.action;
    this.actionDetails = response.actionDetails;
    this.data = response.data;
    this.error = response.error;
    this.errors = response.errors;
    this.fullException = response.fullException;
    this.timestamp = new Date(response.timestamp);
  }

  static getClassName(): string {
    return 'APIResponse';
  }

  getClassName(): string {
    return APIResponse.getClassName();
  }

  getResult(): number {
    switch (this.result) {
      case 0:
        return APIResult.Success;
      case 1:
        return APIResult.Failure;
      case 2:
        return APIResult.Unknown;
      case 3:
        return APIResult.Unknown;
      default:
        return APIResult.Unknown;
    }
  }

  getAction(): APIResponseAction {
    switch (this.action) {
      case 0:
        return APIResponseAction.None;
      case 1:
        return APIResponseAction.Reload;
      case 2:
        return APIResponseAction.Redirect;
      case 3:
        return APIResponseAction.FlashMessage;
      default:
        return APIResponseAction.None;
    }
  }

  success(): boolean {
    return this.getResult() === APIResult.Success;
  }

  failed(): boolean {
    return this.getResult() === APIResult.Failure;
  }
}
